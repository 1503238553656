<template>
  <div class="d-flex flex-column justify-center pt-10">
    <div class="d-flex flex-column justify-center mt-10 mx-auto">
      <v-img
        class="logo2"
        src="../assets/cot_logo2.png"
      >
      </v-img>
      <v-card
        class="elevation-12 size-card mt-10"
        style="max-width: 600px;"
      >
        <v-card-text class="pt-8">
          <p>
            Use this toggle button to set the default url between version 5 and 6 of Cashontime.
          </p>
          <two-state-btn-toggle
            class="toggle"
            :items="[
              {label: 'V5', value: false},
              {label: 'V6', value: true}
              ]"
            :value.sync="isV6"
          />
          <!-- <v-switch v-model="isV6" label="v6"/> -->
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    TwoStateBtnToggle: () => import('@/components/two-state-btn-toggle')
  },
  computed: {
    isV6: {
      get () { return this.dataIsV6 },
      set (v) {
        if (this.dataIsV6 !== v) {
          this.dataIsV6 = v
          this.save()
        }
      }
    }
  },
  data () {
    return {
      dataIsV6: false
    }
  },
  methods: {
    async query () {
      const response = await this.$http().get('/switchV5V6')
      this.isV6 = response.data.isV6
    },
    async save () {
      await this.$http().post('/switchV5V6', { isV6: this.isV6 })
    }
  },
  mounted () {
    this.query()
  }
}
</script>

<style lang="stylus" scoped>
.switcher
  white-space nowrap

.toggle >>> span
  font-size 1.4em !important

.logo2
  width 100%
  max-width 600px
  max-height 600px
  margin-left auto
  margin-right auto
  z-index 2
</style>
